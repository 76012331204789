import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/sign-up-for-mentorship-section1";
import ReviewSection from "../sections/sign-up-for-mentorship-section7";
import PricingSection from "../sections/sign-up-for-mentorship-section8";
import SubscriptionFAQSection from "../sections/sign-up-for-mentorship-section9";
import Section3 from "../sections/sign-up-for-mentorship-section3";
import Section4 from "../sections/index-section6-multiple-use";
import Section5 from "../sections/sign-up-for-mentorship-section6";
import Section6 from "../sections/new-themed-mentorship-section3-multiple-use";
import SEO from "../components/seo";

export default function SignUpforMentorship() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.white}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >

      <SEO title={"Sign Up for Mentorship & Coaching"} description={"Sign up for WomenWorks personalised 1-on-1 mentorship / coaching and access to mentors’ professional network to help through every stage of your professional life."} />
      
      <Section1/>
      <ReviewSection/>
      <PricingSection/>
      <SubscriptionFAQSection/>
      <Section3/>
      <Section4
        Background = {props => props.theme.colors.accent_yellow70}
        TextColor = {props => props.theme.colors.gray_dark2}
        ColorHeart = {props => props.theme.colors.accent_yellow_dark}

        ButtonColor={props => props.theme.buttons.primary_button_violet.normal.color} 
        ButtonBorder={props => props.theme.buttons.primary_button_violet.normal.border_color} 
        ButtonBackground={props => props.theme.buttons.primary_button_violet.normal.background_color}
        
        ButtonColorHover={props => props.theme.buttons.primary_button_violet.hovered.color}
        ButtonBorderHover={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
        ButtonBackgroundHover={props => props.theme.buttons.primary_button_violet.hovered.background_color}

        ButtonColorPressed={props => props.theme.buttons.primary_button_violet.pressed.color}
        ButtonBorderPressed={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
        ButtonBackgroundPressed={props => props.theme.buttons.primary_button_violet.pressed.background_color}
      ></Section4>
        <Section5/>
        <Section6
          BackgroundRight = {props => props.theme.colors.secondary_green_shade20}
          BackgroundLeft = {props => props.theme.colors.secondary_green10}
          TextColor = {props => props.theme.colors.white}

          TitleLeft = "Lead, motivate, and inspire."
          ContentLeft = "Enlarge your impact and gain access to WomenWorks LadyBosses network could benefit women in different stages of professional career."

          TitleRight= "Still have questions?"
          ContentRight = "On the fence about investing your time for a mentorship? Sign up for a free 15-minute pre-mentorship consultation session to find out more!"

          ButtonTextLeft = "Become a mentor"
          ButtonLinkLeft = "https://bit.ly/womenworksmentorsignupnew"
          ButtonTextRight = "Connect with the WomenWorks team"
          ButtonLinkRight = "https://bit.ly/prementorshipWW"

          ButtonColorRight={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
          ButtonBorderRight={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
          ButtonBackgroundRight={props => props.theme.buttons.primary_button_yellow_green.normal.background_color}
          
          ButtonColorHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
          ButtonBorderHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
          ButtonBackgroundHoverRight={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

          ButtonColorPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
          ButtonBorderPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
          ButtonBackgroundPressedRight={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}

          ButtonColorLeft={props => props.theme.buttons.primary_button_yellow_green.normal.color} 
          ButtonBorderLeft={props => props.theme.buttons.primary_button_yellow_green.normal.border_color} 
          ButtonBackgroundLeft={props => props.theme.buttons.primary_button_yellow_green.normal.background_color}
          
          ButtonColorHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.color}
          ButtonBorderHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.border_color} 
          ButtonBackgroundHoverLeft={props => props.theme.buttons.primary_button_yellow_green.hovered.background_color}

          ButtonColorPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.color}
          ButtonBorderPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} 
          ButtonBackgroundPressedLeft={props => props.theme.buttons.primary_button_yellow_green.pressed.background_color}/>
    </Layout>   
  )
}
