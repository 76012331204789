import React, { useContext } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { css} from "@emotion/core";
import Card from 'react-bootstrap/Card';
import { faArrowAltCircleRight, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import WithLocation from "../components/query-param";
import PropTypes from "prop-types";

const SectionBackground = styled.div`
  background-color : ${props => props.theme.colors.white};
  color : ${props => props.theme.colors.gray_dark};
  padding-bottom:50px;
`
const CardAccordion = styled(Card)`
  border: 2px solid ${props => props.theme.colors.gray_border};
  border-left:none;
  border-right:none;
  .card-header {
    padding:0;
    background-color: ${props => props.theme.colors.white};
    border-bottom:none;
  }
  .card-body {
    text-align: justify;
    padding-left:5px;
    padding-top:0;
    padding-bottom:15px;
    span {
      margin-left:15px;
    }
    div {
      margin-top:10px;
    }
  }
  .row-video {
    img {
      width:122px;
      height:70px;
      object-fit:contain;
    }
  }
  .col-video{
    @media(min-width:1200px) {
      padding-right:0;
    }
  }
`

const FAArrow = styled(FontAwesomeIcon)`
  font-size:1.5rem;
  color: ${props => props.theme.colors.primary_violet};
  &:hover, &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.colors.primary_violet_dark};
  }
`

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <div
        css={css`
          font: Bold 20px/28px Roboto;
          outline:none;
          padding-top:30px;
          padding-bottom:30px};
          @media(max-width:767px){
            padding-top:15px;
            padding-bottom:15px};
          }
        `}
        onClick={decoratedOnClick}
        onKeyDown={decoratedOnClick}
        role="button"
        tabIndex={0}
      >
        <Row>
          <Col xs="10" md="11">
            {children}
          </Col>
          <Col xs="2" md="1" className="d-flex justify-content-end">
            <FAArrow icon={isCurrentEventKey ? faArrowAltCircleDown : faArrowAltCircleRight} />
          </Col>
        </Row>
      </div>
    );
  }

  const faq = [
    {
        id : "one",
        title : "What makes a subscription different from the normal mentorship packages?",
        description :"With a subscription, you are entitled to unlimited mentorship sessions within the chosen timeframe (1 month / 3 months). You can use your subscription package to meet as many mentors as possible or to build a long-term relationship with a mentor that you vibe with!"
    },
    {
      id : "two",
        title : "When does my subscription package start?",
        description : "The validity period of your subscription package starts from the date you start your first mentorship session."
    },
    {
      id : "three",
        title : "How many sessions am I allowed to take?",
        description :"You can take as many sessions as you can, though this may also depend on whether you and/or your desired mentor have the capacity to do mentorships within the time period."
    },
    {
      id : "four",
        title : "I’d like to change my mentor / my goals have changed since the first mentoring session. What should I do?",
        description :"Please send an email with the subject ‘Mentee Subscription Info Update’ to our team at ",
        hrefText: "care@womenworks.io",
        href: "mailto:care@womenworks.io",
        description2:". Provide details on your new mentoring goals and what kind of mentor you need. "
    },
    {
      id : "five",
        title : "What do I do once my subscription ends? How do I renew my subscription?",
        description :"Near the end of your subscription, WomenWorks team will send you an email as a reminder. You may choose to continue your subscription package through the steps outlined in the email.",
    },
    {
      id : "six",
        title : "If I want to do multiple sessions with the same mentor, can I directly schedule with the mentor or should I still go through the WomenWorks team?",
        description :"If you already agree to commit to multiple sessions, you and your mentor can directly set up the schedules. Otherwise, you can reach out to us. Please always inform us of the mentoring appointments that you have set up."
    },
    {
      id : "seven",
        title : "I’ve signed up for a subscription package, but it turns out WomenWorks doesn’t have a mentor that suits me. What should I do?",
        description :"We always try to fulfill the mentee’s request as closely as possible. However, sometimes we face hurdles in securing mentors that could fully satisfy the request. Should there be no existing WomenWorks mentor that suits your needs at the moment, we can pause your subscription until we find the mentor that would fit your needs. Subscription fees are non-refundable."
    }
]
  function SignUpforMentorshipSection3({search}) {
    let {open_faq} = search;
    open_faq = open_faq === 'true' ? true : false;
    return (      
      <SectionBackground id="subscription_faq">
        <Container>
          <Row css={css`justify-content:center;`}>
              <Col lg="10">
            <Accordion defaultActiveKey={open_faq?'2a' : '0'}>
                <CardAccordion>
                <Card.Header>
                  <ContextAwareToggle eventKey='2a'>Subscription FAQ</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey='2a'>
                    <Row css={css`justify-content:center;`}>
            <Col lg="10">
            <Accordion defaultActiveKey="0" css={css`margin-bottom:25px;`}>
            {faq.map((content,i)=>
              <CardAccordion>
                <Card.Header id={ content.id }>
                  <ContextAwareToggle eventKey={i}>{content.title}</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={i}>
                  <Card.Body>
                      {content.description} <OutboundLink href={content.href}>{content.hrefText}</OutboundLink>{content.description2}
                  </Card.Body>
                </Accordion.Collapse>
              </CardAccordion>
            )}
            </Accordion>
        </Col>
        </Row>
            </Accordion.Collapse>
            </CardAccordion>
            </Accordion>
            </Col>
    </Row>
    </Container>
    </SectionBackground>
    )
}

SignUpforMentorshipSection3.propTypes = {
  props: PropTypes.object,
}

export default WithLocation(SignUpforMentorshipSection3);

  

