import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import styled from "@emotion/styled";
import {Global, css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

const SeventhSectionBackground = styled.div`
  background-color : ${props => props.theme.colors.white};
  color : ${props => props.theme.colors.gray_dark};
  @media(min-width:769px){
    padding-top:35px;
    padding-bottom:50px;
  }
  @media(max-width:768px){
    padding-top:35px;
    padding-bottom:50px;
  }
`
const SeventhSectionTitle = styled.div`
  font: 28px/62px Abril Fatface;
  text-align:center;
  margin-bottom:50px;
  @media(max-width:767px){
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom:25px;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`
const SeventhSectionCarousel = styled(Carousel)`
  height: 80%;
  .carousel-row {
    margin-left:0;
    margin-right:0;
    .carousel-col {
      padding-left:0;
      @media(max-width:1199px){
        padding-right:8px;
      }
      @media(max-width:767px){
        margin-bottom:15px;
        padding-right: 15px;
        padding-left: 15px;
      }
      .card {
        background-color:transparent;
        text-align: center;
        border:none;
        max-width:474px;
        @media(max-width:767px){
            .car-col-xs-12 {
                flex: 0 0 100%;
                max-width: 100%
            }
        }
        @media(min-width:992px){
            .car-col-sm-auto {    
                flex: 0 0 auto;
                width: auto;
                max-width: 100%;
            }
        }
        .card-body {
          padding-left:0;
          padding-right:0;
          padding-top:10px;
          padding-bottom:0;
          .card-title {
            font: bold 20px/28px Roboto;
            text-align: left;
          }
          .card-text {
            font: 20px/28px Roboto;
            margin-bottom:10px;
            text-align: left;
          }
        }
      }
    }
  }
  
`
const FAArrowButton = styled(FontAwesomeIcon)`
  font-size:3rem;
  color:${props => props.theme.colors.secondary_green};
  transition: 0.25s ease-out;
  &:hover, &:focus, &.focus, &:active, &.active {
    color:${props => props.theme.colors.accent_yellow_hover};
    @media(min-width:1200px){
      margin-top:-3px;
    }
  }
`
const MentorshipReviews = [
  {id:1,picture:"pln.png", menteeName:"Meira Dewi A", 
  profession:"Assistant Analyst, PT. PLN (Persero)", review: "“I truly met an amazing mentor. I didn’t feel like I was being taught, instead, I felt I got a new friend who’s truly care about me. She gave me a lot of insights about discovering myself. I learned about my value as well as my shortcomings that I need to improve.”"},
  {id:2,picture:"mamikos.png", menteeName:"Isvi Mega Kurnia", 
  profession:"Campaign Executive, Mamikos", review: "“My mentoring sessions were truly eye and mind opening! I know what I should do immediately and in the future. My mentor is objective in answering my questions, so there are no biased answers and it helps me a lot to get out of my problems.”"},
  {id:2,picture:"bytedance.jpg", menteeName:"Raihan Zahirah M", 
  profession:"Partnership Manager, ByteDance", review: "“This mentorship was way beyond my expectation. Our session was very enjoyable and not rigid at all. My mentor gave me practical and insightful guidance to help me ace my MBA interview. My MBA interview went very smoothly!”"},
  {id:2,picture:"apiary.png", menteeName:"Jessica J", 
  profession:"COO, Apiary Academy & Coworking", review: "“I felt grateful meeting my mentor as turned out we faced the same doubts and difficulties. My mentor also equipped me with practicable and actionable advices that could help me gain confidence in leading my team.”"},
];

export default function Home() {
  return (      
  <SeventhSectionBackground id="womenworks-lady-bosses">
    <Container>
      <Row className="justify-content-center">
        <Col xs="10">
          <SeventhSectionTitle>
            What our mentees say about the program:
          </SeventhSectionTitle>
          <Global
            //To style Bootstrap Carousel
            styles={css`
            .carousel-control-prev {
              opacity:1;
              margin-left:-141px;
              @media(max-width:1199px){
                margin-left:-125px;
              }
              @media(max-width:991px){
                margin-left:-95px;
              }
              @media(max-width:767px){
                margin-left:-30px;
              }
            }
            .carousel-control-next {
              opacity:1; 
              margin-right:-141px;
              @media(max-width:1199px){
                margin-right:-125px;
              }
              @media(max-width:991px){
                margin-right:-95px;
              }
              @media(max-width:767px){
                margin-right:-30px;
              }
            }
            .carousel-inner {
              @media(max-width:767px){
                margin-bottom:50px;
              }
            }
            `}
          />
          <SeventhSectionCarousel className="third-section-carousell" indicators={false} 
            prevIcon = {<FAArrowButton icon={faArrowAltCircleLeft} />}
            nextIcon = {<FAArrowButton icon={faArrowAltCircleRight} />}
          >
            {MentorshipReviews.map(review => 
              <Carousel.Item>
                <Row className="carousel-row" css={css`justify-content:space-around;`}>
                    <Col xs="12" sm="auto" css={css`
                        justify-content: center;
                        align-content: center;
                        display: flex;`}>
                        <img alt={review.picture} src={"/testimonials/" + review.picture}
                        css={css`
                        margin-bottom: 20px;
                        object-fit: contain;
                        margin-right: 43px;
                        @media(min-width:1200px){
                            width:218px;
                        }
                        @media(min-width:768px) and (max-width:1199px){
                          height:calc(150/1440*100vw);
                        }
                        @media(max-width:767px){
                            height:100px;
                        }
                        @media(max-width:500px){
                          height:calc(200/1440*100vw);
                        }
                        `}/>
                    </Col>
                    <Col xs="12" sm="auto" className="carousel-col d-flex" 
                    css={css``}>
                      <Card>
                        <Card.Body>
                          <Card.Text>
                            {review.review}
                          </Card.Text>
                          <Card.Title>{review.menteeName} - <span css={css`font-style:italic;`}>{review.profession}</span></Card.Title>
                        </Card.Body>
                      </Card>
                    </Col>
                </Row>
              </Carousel.Item>
            )}
            
          </SeventhSectionCarousel>
        </Col>
      </Row>
    </Container>
  </SeventhSectionBackground>
  )
}